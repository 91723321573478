@import url(https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css);

.swiper {
    width: 100%;
    // height: 400px;
    max-height: 450px;
    margin: 20px auto;
}

.swiper-slide {
    text-align: center;
    font-size: 1rem;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    // height: 350px !important;
    object-fit: cover;
} 

// @media only screen and (max-width: 1399.98px) {
//     .swiper-slide img {width:0px;}

// }