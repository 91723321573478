

$primary: #37a000  !default;
$primary-light: lighten($primary, 75%)  !default;
$secondary: #FBBC05  !default;
$blue:    #4285f4 !default;
$red:     #ea4335 !default;
$yellow:  #fbbc05 !default;
$dark-fnt-green:  #234e5f !default;

$primary-500: #37a00011 !default;
$blue-500:    #4285f415 !default;
//$red-500:     #ea43351f !default; //ffa8f2
$red-500:     #ffa8a83d !default; 
$yellow-500:  #fbbc0515 !default;

$white: #fff !default;

$gray-100: #f8f9fa !default;
$gray-200: #eee !default;
$gray-300: #dee2e6 !default;
$gray-400: #ccc !default;
$gray-500: #adb5bd !default;
$gray-600: #797676 !default;
$gray-700: #495057 !default;
$gray-800: #333 !default;
$gray-900: #222 !default;

$ggray-010: #d0d0d025 !default;
$ggray-020: #d0d0d033 !default;
$ggray-030: #d0d0d044 !default;
$ggray-040: #d0d0d055 !default;
$ggray-050: #d0d0d066 !default;
$ggray-060: #d0d0d077 !default;
$ggray-070: #d0d0d088 !default;
$ggray-080: #d0d0d099 !default;
$ggray-090: #d0d0d0aa !default;
$ggray-100: #d0d0d0 !default;
$ggray-200: #c0c0c0 !default;
$ggray-300: #b0b0b0 !default;
$ggray-400: #a0a0a0 !default;
$ggray-500: #909090 !default;
$ggray-600: #808080 !default;
$ggray-700: #707070 !default;
$ggray-800: #606060 !default;
$ggray-900: #505050 !default;

$rpz-border-radius: 6px;
$rpz-border-radius2: 2px;
$rpz-border-radius4: 4px;
$rpz-border-radius10: 10px;
$rpz-border: 1px solid $gray-200;
$rpz-card-w: 300px;


/* $blue-100: #f8f9fa !default;
$blue-200: #e9ecef !default;
$blue-300: #dee2e6 !default;
$blue-400: #ced4da !default;
$blue-500: #adb5bd !default;
$blue-600: #6c757d !default;
$blue-700: #495057 !default;
$blue-800: #343a40 !default;
$blue-900: #212529 !default;

$red-100: #f8f9fa !default;
$red-200: #e9ecef !default;
$red-300: #dee2e6 !default;
$red-400: #ced4da !default;
$red-500: #adb5bd !default;
$red-600: #6c757d !default;
$red-700: #495057 !default;
$red-800: #343a40 !default;
$red-900: #212529 !default;

$yellow-100: #f8f9fa !default;
$yellow-200: #e9ecef !default;
$yellow-300: #dee2e6 !default;
$yellow-400: #ced4da !default;
$yellow-500: #adb5bd !default;
$yellow-600: #6c757d !default;
$yellow-700: #495057 !default;
$yellow-800: #343a40 !default;
$yellow-900: #212529 !default; */

/* $colors: (

);*/

/* $theme-colors: (
  "rpzblue": $blue,
  "rpzred": $red,
  "rpzyellow": $yellow
);  */


$success: #7eff6e !default;
$enable-gradients: true;

$link-color: $primary !default;
// $link-color: darken($primary, 15%) !default;

$white: #fff  !default;




$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;

$td-enable-google-fonts: false !default;




