:root{

}

html { height: 100%; }

//box
body {
    /* padding-top: 3rem; */
    height: 100%;
    /* padding-bottom: 3rem; */
    /* color: #5a5a5a;  */
    background: #fafafa;
    line-height: 1.6;
}

/* h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    color: #222222e5;
} */

a{
    text-decoration: none;
}

.fnt-t1{
    color: $dark-fnt-green;
}

.fnt-t4{
    color: #667085;
}

.fnt-p{
    color: #667085;
}


.fnt-10{
    font-size: 10px !important;
    line-height: 12px;
}

.fnt-12{
    font-size: 12px !important;
    line-height: 12px;
}

.fnt-14{
    font-size: 14px !important;
}

.fnt-16{
    font-size: 16px !important;
}

.fnt-18{
    font-size: 18px !important;
}

.fnt-20{
    font-size: 20px !important;
}

.fnt-22{
    font-size: 22px !important;
}

.fnt-24{
    font-size: 24px !important;
}

.fnt-26{
    font-size: 26px !important;
}

.fnt-30{
    font-size: 30px !important;
}

.fnt-w5{
    font-weight: 500 !important;
}

.fnt-w6 {
    font-weight: 600 !important;
}

/* cursor */
.pointer{
    cursor:pointer; 
}

.pointer > *{
    cursor:pointer; 
}

.btn{
    box-shadow: 0 10px 25px rgba(55, 160, 0, 0.2);
    font-weight: 500;
    height: 38px;
}

.btn-lg{
    height: 48px !important;
}

.fx{
    display: flex;
}

.fx-g{
    flex: 1 1 auto;
}

.fx-row{
    display: flex !important;
    flex-direction: row !important;
}

.fx-col{
    display: flex !important;
    flex-direction: column !important;
}

.fx-wrap{
    display: flex !important;
    flex-wrap: wrap !important;
}

/* position */
.pos-fx{
    position: fixed !important;
}

.pos-r{
    position: relative !important;
}

.pos-r-fx{
    position: relative !important;
}

.pos-a{
    position: absolute;
}

.pos-a-fx{
    position: absolute !important;
}


  
/* .navbar{
    box-shadow: 0 10px 25px #e4e4e4; 
}
 */
/* navbar */
.navbar-brand{
    max-width:260px; 
    margin-top: -5px;
    padding-bottom: 0px;
}

.main-header{
    padding: 0px;
}

.main{
    padding-top: 76px;//5rem;
}

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; 
} 

/* Margin */
.m-a{
    margin: auto !important;
}

.m-0a{
    margin: 0 auto;
}

.m-0{
    margin: 0px !important;
}



.m-10{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
}
.mx-10{
    margin-left: 10px;
    margin-right: 10px;
}

.my-10{
    margin-top: 10px;
    margin-bottom: 10px;
}

.mt-10{
    margin-top: 10px;
}

.mr-10{
    margin-right: 10px;
}

.mb-10{
    margin-bottom: 10px;
}

.ml-10{
    margin-left: 10px;
}

.my-20{
    margin-top: 20px;
    margin-bottom: 20px;
}

.mt-20{
    margin-top: 20px;
}

.mb-20{
    margin-bottom: 20px;
}

.my-30{
    margin-top: 30px;
    margin-bottom: 30px;
}

.my-10 {
    margin-top: ($spacer * 10) !important;
    margin-bottom: ($spacer * 10) !important;
}

.mt-10 {
    margin-top: ($spacer * 10) !important;
}

.mb-10 {
    margin-bottom: ($spacer * 10) !important;
}

.my-50{
    margin-top: 50px;
    margin-bottom: 50px;
}

.my-100{
    margin-top: 100px;
    margin-bottom: 100px;
}

.my-100{
    margin-top: 100px;
    margin-bottom: 100px;
}

.mt-30{
    margin-top: 30px;
}

.mb-30{
    margin-bottom: 30px;
}

.mt-40{
    margin-top: 40px;
}

.mb-40{
    margin-bottom: 40px;
}

.mt-50{
    margin-top: 50px;
}

.mb-50{
    margin-bottom: 50px;
}

.mt-100{
    margin-top: 100px !important; 
}

.mb-100{
    margin-bottom: 100px !important; 
}


.py-10 {
    padding-top: ($spacer * 10) !important;
    padding-bottom: ($spacer * 10) !important;
}

.pt-10 {
    padding-top: ($spacer * 10) !important;
}

.pb-10 {
    padding-bottom: ($spacer * 10) !important;
}




.pt-10{
    padding-top: 10px;
}

.pt-20{
    padding-top: 20px;
}

.pt-30{
    padding-top: 30px;
}

.pb-10{
    padding-bottom: 10px;
}

.pb-20{
    padding-bottom: 20px;
}

.pb-30{
    padding-bottom: 30px;
}

.pb-50{
    padding-bottom: 50px;
}

.pb-70{
    padding-bottom: 70px;
}

.pb-100{
    padding-bottom: 100px;
}

.w-fc{
    width: fit-content;
}

.h-fc{
    height: fit-content;
}

.fc{
    width: fit-content;
    height: fit-content;
}

.w-90{
    width: 90%;
}

.w-96{
    width: 96%;
}

.w-98{
    width: 98%;
}

.w-100{
    width: 100%;
}

.h-100{
    height: 100%;
}

.h-80vh{
    height: 80vh;
}

.h-100vh{
    height: 100vh;
}

.mh-img-blog{
    max-height: 20%;
}

.rounded-xl{
    border-radius: 6px !important;
}

.rounded-xxl{
    border-radius: 10px !important;
}

.grid {
    display: grid;
}

.cards{
    display: grid;
    grid-template-columns: auto auto auto auto;
    // grid-template-columns: repeat(auto-fill, minmax($rpz-card-w, 1fr));
    grid-gap: 10px;
}

@media (max-width: 1024px) { 
    .cards{
        grid-template-columns: auto auto;
    }
}

@media (max-width: 576px) { 
    .cards{
        grid-template-columns: auto;
    }
}


/* .g-5{
    grid-gap: 5px;
} */

.g-10{
    grid-gap: 10px;
}

.brd{
    border: 1px solid $gray-200;
}

.b-0{
    bottom: 0px !important;
} 

.brd-r{
    border-right: 1px solid $gray-200;
}

.brd-b{
    border-bottom: 1px solid $gray-200;
}

.brdr{
    border-radius: $rpz-border-radius10;
}

.raise:hover {
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.35) !important; 
}

/* .row>*{
    max-width: 100%;
    padding-right: calc(1.5rem * .5);
    padding-left: calc(1.5rem * .5);
    margin-top: 1.5rem;
}  */

.gap-2 {
    gap: .5rem !important;
}
 
.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
} 


// Font
.fs-4 {
    font-size: calc(1.275rem + .3vw) !important;
}

.fs-5 {
    font-size: 1.25rem !important;
}

.display-5 {
    font-size: calc(1.15rem + 2.1vw);
}

.fs-menu{
    font-size: 1.1rem;
    font-weight: 500;
}

// Font Weight
.fw-4{
    font-weight: 400;
}

.fw-5{
    font-weight: 500;
}

.fw-6{
    font-weight: 600;
}

.fw-7{
    font-weight: 700;
}

// Line Height
.lh-1 {
    line-height: 1 !important;
}

.lh-125 {
    line-height: 1.25 !important;
}

//margins
.ms-auto {
    margin-left: auto !important;
}

//color

.blue{
    color: $blue;
}

.red{
    color: $red;
}

.yellow{
    color: $yellow;
}

.bg-blue{
    background-color: $blue;
}

.bg-red{
    background-color: $red;
}

.bg-yellow{
    background-color: $yellow;
}

.bg-primary-l{
    background-color: $primary-500;
}

.bg-blue-l{
    background-color: $blue-500;
}

.bg-red-l{
    background-color: $red-500;
}

.bg-yellow-l{
    background-color: $yellow-500;
}


.bg-light {
    background-color: $ggray-020 !important;
    // background-color: #f5f5f5 !important;
}

.bg-light2 {
    background-color: $ggray-050 !important;
}

.bg-dark-rpz2 {
    background-color: #403f4c !important;
}

.bg-dark-rpz {
    background-color: #505050 !important;
}

.bg-gray{
    background-color: $ggray-100;
}

.bg-gray-01{
    background-color: $ggray-010;
}

.bg-gray-5{
    background-color: $ggray-500;
}

.bg-gray-7{
    background-color: $ggray-700;
}


.text-muted-rpz{
    color: #b3b3b3;
}

.text-muted-rpz:focus, 
.text-muted-rpz:hover {
  color: #fff;
}

/* align */
.center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

/* align text vertically - need fx */
.center-ver{
    vertical-align: middle;
}

/* center vertical */
.cent-vert{
    display: flex;
    align-items: center;
}

.center-cnt{
    align-content: center;
}

.fx-cent{
    display: flex !important;
    justify-content: center !important;
}

.fx-cent-self{
    display: flex;
    align-self: center;
}

.txt-l{
    text-align: left;
}

.txt-cent{
    text-align: center;
}

.txt-r{
    text-align: right;
}

.txt-outline{
    color: transparent;
    text-shadow: -1px -1px 0 #c45151, 1px -1px 0 #d85252, -1px 1px 0 #fa7272, 1px 1px 0 #f06262;
}


// .bg-testimonial {
// /*  width: 100%;
//     top: 0;
//     left: 0; */
//     background: #f1f6f9;
// }

// .bg-testimonial svg{
//     height: 100%;
//     width: 100%;
// }

// #testimonials li svg{
//     color: #ffc219
// }

// #testimonials li svg.inactive{
//     color: #c2c8cc;
// }


//link
.link-light {
    color: #f8f9fa;
}


/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
    visibility: hidden; /* Hidden by default. Visible on click */
    min-width: 250px; /* Set a default minimum width */
    margin-left: -125px; /* Divide value of min-width by 2 */
    background-color: #333; /* Black background color */
    color: #fff; /* White text color */
    text-align: center; /* Centered text */
    border-radius: 2px; /* Rounded borders */
    padding: 16px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 1; /* Add a z-index if needed */
    left: 50%; /* Center the snackbar */
    bottom: 30px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
    visibility: visible; /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
    However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

.ft-icon{
    width: 32px !important; 
    height: 32px !important; 
}

.ft-img{
    width: 36px !important; 
    height: 36px !important; 
}

//docs
.text{
    &-blue{
        color: $red !important;
    }

    &-red{
        color: $red !important;
    }

    &-yellow{
        color: orange !important;
    }    
    
    &-bg-blue{
        background-color: #8eb6f8 !important;
        border-radius: 5px;
        padding: 0px 5px;
    }

    &-bg-red{
        background-color: $red !important;
        border-radius: 5px;
        padding: 0px 5px;
    }

    &-bg-yellow{
        background-color: $yellow !important;
        border-radius: 5px;
        padding: 0px 5px;
    }

    &-bg-hi{

        background-color: #8eb6f8 !important;
        border-radius: 5px;
        padding: 0px 5px;
        font-size: small;
    
        &-red{
            background-color: $red !important;
            border-radius: 5px;
            padding: 0px 5px;
            font-size: small;
        }
    
        &-yellow{
            background-color: $yellow !important;
            border-radius: 5px;
            padding: 0px 5px;
            font-size: small;
        } 
    }
}

ic-app,
ic-apps{
    background-color: #8eb6f8 !important;
    border-radius: $rpz-border-radius;
    padding: 1px 5px; 
    margin: 0;
    font-size: 85%;
    font-family: var(--bs-font-monospace);
}

ic-app::before{
    content: 'ICApp';
}

ic-apps::before{
    content: 'ICApps';
}

ti-p{
    background-color: #ecaf06 !important;
    border-radius: $rpz-border-radius;
    padding: 0px 5px;
    font-size: small;
}

ti-p::before{
    content: 'Tip';
}

//colors
pri-c{
    color: $primary;
    font-weight: 600;
}

pri-cu{
    text-decoration: underline $primary 10px;
    line-height: 1.5;
}

blue-c{
    color: $blue;
    font-weight: 600;
}

red-c{
    color: $red;
    font-weight: 600;
}

yellow-c{
    color: $yellow;
    font-weight: 600;
}

pro{
    background-color: silver;//$gray-500;
    color: #fff;
    border-radius: $rpz-border-radius;
    padding: 1px 5px; 
    margin: 0;
}

pro::before{
    content: 'pro';
}

ee{
    background-color: $yellow;
    color: #fff;
    border-radius: $rpz-border-radius;
    padding: 1px 5px; 
    margin: 0;
}

ee::before{
    content: 'ee';
}

//alerts
.alert-icon{
    margin-top: 0.25rem;
    color: $yellow;
}

.lead{
    font-size: 1rem !important;
    font-weight: 400 !important;
}

.lead p{
    font-size: 1.225rem !important;
    font-weight: 300 !important;
}

@media (min-width: 1920px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl, .container-xxxl, .container-xxxxl {
        max-width: 1600px;
    }
}

@media (min-width: 1600px){
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl, .container-xxxl {
        max-width: 1430px;
    }
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.page-item.active .page-link {
    color: #fff;
    background-color: $primary;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #37a000;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.marquee {
    overflow: hidden;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    display: flex;
}
  
.marquee h1{
    font-size: 5em;
    white-space: nowrap;
    text-transform: uppercase
}

.txt-hero{
    width: 60%;
}

.img-hero{
    width: 80%;
}


@media (max-width: 576px) { 

}

@media (max-width: 768px) { 
    
}

@media (max-width: 855px) { 
    .txt-hero{
        width: 100%;
    }
    
    .img-hero{
        width: 100%;
    }
}

@media (max-width: 1024px) {
    .txt-hero{
        width: 80%;
    }
}

@media (max-width: 1200px) { 
    
}

.fnt-wbkeep{
    word-break:keep-all
}

.fnt-wbrk{
    word-wrap: break-word
}

.fnt-nw{
    white-space: nowrap;
}
